import _export from "../internals/export";
import _isPure from "../internals/is-pure";
import _descriptors from "../internals/descriptors";
import _global3 from "../internals/global";
import _path from "../internals/path";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _isForced from "../internals/is-forced";
import _hasOwnProperty from "../internals/has-own-property";
import _inheritIfRequired from "../internals/inherit-if-required";
import _objectIsPrototypeOf from "../internals/object-is-prototype-of";
import _isSymbol from "../internals/is-symbol";
import _toPrimitive from "../internals/to-primitive";
import _fails from "../internals/fails";
import _objectGetOwnPropertyNames from "../internals/object-get-own-property-names";
import _objectGetOwnPropertyDescriptor from "../internals/object-get-own-property-descriptor";
import _objectDefineProperty from "../internals/object-define-property";
import _thisNumberValue from "../internals/this-number-value";
import _stringTrim from "../internals/string-trim";
var $ = _export;
var IS_PURE = _isPure;
var DESCRIPTORS = _descriptors;
var _global2 = _global3;
var path = _path;
var uncurryThis = _functionUncurryThis;
var isForced = _isForced;
var hasOwn = _hasOwnProperty;
var inheritIfRequired = _inheritIfRequired;
var isPrototypeOf = _objectIsPrototypeOf;
var isSymbol = _isSymbol;
var toPrimitive = _toPrimitive;
var fails = _fails;
var getOwnPropertyNames = _objectGetOwnPropertyNames.f;
var getOwnPropertyDescriptor = _objectGetOwnPropertyDescriptor.f;
var defineProperty = _objectDefineProperty.f;
var thisNumberValue = _thisNumberValue;
var trim = _stringTrim.trim;
var NUMBER = "Number";
var NativeNumber = _global2[NUMBER];
var PureNumberNamespace = path[NUMBER];
var NumberPrototype = NativeNumber.prototype;
var TypeError = _global2.TypeError;
var stringSlice = uncurryThis("".slice);
var charCodeAt = uncurryThis("".charCodeAt);

// `ToNumeric` abstract operation
// https://tc39.es/ecma262/#sec-tonumeric
var toNumeric = function (value) {
  var primValue = toPrimitive(value, "number");
  return typeof primValue == "bigint" ? primValue : toNumber(primValue);
};

// `ToNumber` abstract operation
// https://tc39.es/ecma262/#sec-tonumber
var toNumber = function (argument) {
  var it = toPrimitive(argument, "number");
  var first, third, radix, maxCode, digits, length, index, code;
  if (isSymbol(it)) throw new TypeError("Cannot convert a Symbol value to a number");
  if (typeof it == "string" && it.length > 2) {
    it = trim(it);
    first = charCodeAt(it, 0);
    if (first === 43 || first === 45) {
      third = charCodeAt(it, 2);
      if (third === 88 || third === 120) return NaN; // Number('+0x1') should be NaN, old V8 fix
    } else if (first === 48) {
      switch (charCodeAt(it, 1)) {
        // fast equal of /^0b[01]+$/i
        case 66:
        case 98:
          radix = 2;
          maxCode = 49;
          break;
        // fast equal of /^0o[0-7]+$/i
        case 79:
        case 111:
          radix = 8;
          maxCode = 55;
          break;
        default:
          return +it;
      }
      digits = stringSlice(it, 2);
      length = digits.length;
      for (index = 0; index < length; index++) {
        code = charCodeAt(digits, index);
        // parseInt parses a string to a first unavailable symbol
        // but ToNumber should return NaN if a string contains unavailable symbols
        if (code < 48 || code > maxCode) return NaN;
      }
      return parseInt(digits, radix);
    }
  }
  return +it;
};
var FORCED = isForced(NUMBER, !NativeNumber(" 0o1") || !NativeNumber("0b1") || NativeNumber("+0x1"));
var calledWithNew = function (dummy) {
  // includes check on 1..constructor(foo) case
  return isPrototypeOf(NumberPrototype, dummy) && fails(function () {
    thisNumberValue(dummy);
  });
};

// `Number` constructor
// https://tc39.es/ecma262/#sec-number-constructor
var NumberWrapper = function Number(value) {
  var n = arguments.length < 1 ? 0 : NativeNumber(toNumeric(value));
  return calledWithNew(this) ? inheritIfRequired(Object(n), this, NumberWrapper) : n;
};
NumberWrapper.prototype = NumberPrototype;
if (FORCED && !IS_PURE) NumberPrototype.constructor = NumberWrapper;
$({
  global: true,
  constructor: true,
  wrap: true,
  forced: FORCED
}, {
  Number: NumberWrapper
});

// Use `internal/copy-constructor-properties` helper in `core-js@4`
var copyConstructorProperties = function (target, source) {
  for (var keys = DESCRIPTORS ? getOwnPropertyNames(source) : (
    // ES3:
    "MAX_VALUE,MIN_VALUE,NaN,NEGATIVE_INFINITY,POSITIVE_INFINITY," +
    // ES2015 (in case, if modules with ES2015 Number statics required before):
    "EPSILON,MAX_SAFE_INTEGER,MIN_SAFE_INTEGER,isFinite,isInteger,isNaN,isSafeInteger,parseFloat,parseInt," +
    // ESNext
    "fromString,range").split(","), j = 0, key; keys.length > j; j++) {
    if (hasOwn(source, key = keys[j]) && !hasOwn(target, key)) {
      defineProperty(target, key, getOwnPropertyDescriptor(source, key));
    }
  }
};
if (IS_PURE && PureNumberNamespace) copyConstructorProperties(path[NUMBER], PureNumberNamespace);
if (FORCED || IS_PURE) copyConstructorProperties(path[NUMBER], NativeNumber);
export default {};